.education-full-page{
  display: flex;
}
.edu-ads-contaner{
  margin-left: 6px;
    margin-right: 6px;
    width: 20vw; 
    margin-top: 6em; 
}
.education-detail-page-container {
  flex: 1;
    margin: 1em;
    margin-top: 6em;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  

  .education-detail-course-card {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2em;
    width: 100%;
    max-width: 1500px;
    background-color: #fff;
  }
  

  .education-detail-course-content {
    flex: 1;
    padding: 2em;
  }
  
  .education-detail-course-content h3 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .education-detail-course-content p {
    font-size: 1em;
    margin: 0.5em 0;
  }
  
  .education-detail-subHeading {
    font-weight: bold;
  }
  

  .education-detail-link {
    display: block;
    margin: 1em 0;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .education-detail-link:hover {
    text-decoration: underline;
  }
  

  .education-detail-course-image {
    max-width: 30vw;
    height: fit-content;
    /* background-color: red; */
    
  }
  
  .education-detail-course-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
    background-size: cover;
  }
  

  .request-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1200px;
    padding-right: 2em;
    margin-bottom: 2em;
  }
  
  .requestButton {
    background-color: #ffda6c;
    padding: 0.3em 1em;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .requestButton:hover {
    background-color: #e6c657;
  }

 .education-share-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 1%;
  justify-content: end; 

}
.back-to-courseList-button{
  background-color:#ffda6c ;
  border:0px solid;
  padding: 5px 15px;
  border-radius: 5px;

}

  

  @media (max-width: 768px) {
    .education-detail-course-card {
      flex-direction: column;
      align-items: center;
    }
  
    .education-detail-course-content {
      padding-right: 0;
      text-align: center;
    }
  
    .education-detail-course-image {
      margin-top: 1.5em;
      max-width: 100%;
    }
  
    .request-button-container {
      justify-content: center;
      padding-right: 0;
    }
  }
  
  /* @media (max-width: 480px) {
    .education-detail-course-content h3 {
      font-size: 1.5em;
    }
  
    .requestButton {
      padding: 0.6em 1.5em;
    }
  }   */

@media screen and (max-width:575.97px) {
  .edu-ads-contaner{
    display: none;
  }
  .education-detail-course-content{
    padding: 1em;
  }
  
}