.rc-notification {
  transform: translateX(0);
  z-index: 4444444444;
}
.rc-notification-notice {
  padding: 0;
  background: transparent;
}

.rc-notification-notice-closable {
  position: relative;
}

.rc-notification-notice-closable .rc-notification-notice-close {
  font-size: 23px;
  color: #ffffff;
  position: absolute;
  right: 16px;
  top: 13px;
  font-weight: 300;
  text-shadow: none;
  filter: alpha(opacity=100);
  opacity: 1;
  transition: opacity 0.25s ease;
}

.rc-notification-notice-closable .rc-notification-notice-close:hover {
  opacity: 0.7;
}

.rc-notification-notice-closable .notificationContent {
  padding-right: 45px;
}
