.course-accordion-item {
    margin-bottom: 10px;
}

.course-accordion-header {
    background-color: #f1f1f1;
    cursor: pointer;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.course-accordion-arrow {
    margin-left: 10px; 
    transition: transform 0.3s; 
}

.course-accordion-arrow.open {
    transform: rotate(180deg); 
}

.course-accordion-content {
    padding: 15px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-top: none;
}

.course-video-item {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;  
    align-items: flex-start;  
    margin-bottom: 10px;  
    /* background-color: aqua; */
}

.course-video-title,
.course-video-preview,
.course-video-duration {
    flex: 1;  
    text-align: start;  
    margin-top: 10px;
}

.course-video-preview {
    color: rgb(86, 60, 202);  
    padding: 5px 10px; 
    /* text-decoration: none;  */
    transition: background-color 0.3s;  
    border: 1px solid transparent;  
    border-radius: 5px;  
}

 

 
