.brochure-container {
    background-image: url(../../../assests/brochure-img2.jpg);
    padding: 2rem;
    /* background: white; */
    text-align: center;
    height: 100vh;
    background-size: cover;
    background-position: center;  
    background-repeat: no-repeat;
  }
  
  .brochure-header h3 {
    font-size: 2.0rem;
    margin-top: 5%;
    color: #0f0b02;
    margin-bottom: 0.5rem;
  }
  
  .brochure-header p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .brochure-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }
  
  .brochure-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .brochure-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-icon {
    color: #0073e6;
    margin-bottom: 1rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .card-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .brochure-card:hover .card-icon {
    color: #005bb5;
  }
  

  @media (max-width: 768px) {
    .brochure-container {
     padding: 1rem;
     height: auto;
     background-position: top;
    }

    .brochure-header h3 {
     font-size: 1.8rem;
     margin-top: 2rem;
     margin-top: 20%;
    }

    .brochure-header p {
     font-size: 1rem;
     margin-bottom: 1rem;
    }

    
    .brochure-grid {
     grid-template-columns: 1fr;
     gap: 1rem;
    }


    .brochure-card {
     padding: 1rem;
    }

    .card-title {
     font-size: 1.3rem;
    }

    .card-description {
     font-size: 0.9rem;
    }

    .card-icon {
     font-size: 1.5rem;
    }
}

@media (max-width: 480px) {

    .brochure-container {
     padding: 0.5rem;
    }

    .brochure-header h3 {
     font-size: 1.5rem;
     margin-top: 20%;

    }

    .brochure-header p {
     font-size: 0.9rem;
    }

    .brochure-card {
     padding: 0.8rem;
    }

    .card-title {
     font-size: 1.2rem;
    }

    .card-description {
     font-size: 0.8rem;
    }

    .card-icon {
     font-size: 1.2rem;
    }
}