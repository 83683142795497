.Request-btn-form{
    position: fixed;  
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);  
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  
}

.Request-btn-form form {
    background:white;  
    padding: 20px;
    border-radius: 8px;  
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);  
    width: 400px;  
}

.Request-btn-form div {
    margin-bottom: 15px;  
}

.Request-btn-form label {
    display: block;  
    margin-bottom: 5px;  
    font-weight: bold;  
}

.Request-btn-form input {
    width: 100%;  
    padding: 10px;  
    border: 1px solid #ccc;  
    border-radius: 4px;  
    box-sizing: border-box;  
}

.Request-btn-form button {
    background-color: #007bff;  
    color: white; 
    border: none;  
    border-radius: 4px;  
    padding: 10px 15px;  
    cursor: pointer;  
    margin-right: 10px;  
    transition: background-color 0.3s;  
}

.Request-btn-form button:hover {
    background-color: #0056b3;  
}

.Request-btn-form button[type="button"] {
    background-color: #ccc;  
}

.Request-btn-form button[type="button"]:hover {
    background-color: #999;  
}
.Request-btn-form-btn{
    padding: 15px;
    border: 1px solid black;
    background-color: rgb(228, 177, 36);
    border-radius: 10px;
    cursor: pointer;
}
.request-form-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  